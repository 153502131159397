/* Styling the preview */
.preview {
  background: #fafbfc;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  border-style: solid;
  border-radius: 4px;
  border-width: 14px;
  border-color: #ffffff;
  padding: 30px;
}
.preview h3 {
  /* This is a tragedy */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 200;
  /*font-size: 24px;*/
  line-height: 42px;
  color: #72808a;
  text-align: center;
}

.preview p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
}

.preview_petition {
  /* Could you please let us know why? */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #000000;
}

.preview button {
  /* Rectangle 3 */
  padding: 12px 24px 12px 24px;
  margin-bottom: 24px;
  font-family: "Roboto", sans-serif;
  background: #4a90e2;
  color: #ffffff;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: default;
}

.preview u {
  cursor: default;
}

@media (min-width: 768px) {
  .preview h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 42px;
    color: #72808a;
    text-align: center;
  }
}

@primary-color: #FF644D;@body-background: #FBF8F3;@background-color-base: #FBF8F3;