// Common colors
:root {
  --chart-series-1: #2d9cdb;
  --chart-series-2: #4de8ff;
  --chart-series-3: #ffc600;
  --chart-series-4: #4f4f4f;
  --chart-series-5: #4a90e2;

  --orange-brand: #ff644d;
  --yellow: #ffc600;
  --yellow-sunny: #ff9900;
  --black: #272727;
  --background-base: #fbf8f3;
  --orange-pale: #f9e7d2;
  --orange-more-pale: #fdf8f2;
  --orange-less-pale: #f9e8d4;
  --black-1: #4f4f4f;
  --some-grey: #9e9e9e;
  --some-other-grey: #72808a;
  --lighter-yellow: #f5f5ef;
  --blue-neutral: #4a90e2;
  --black-42: #424242;
  --seashell: seashell;
  --snow: snow;
  --stripe-color: rgb(0, 140, 221) !important;
  --bluish: #dbe2f3;
  --indigo-600: #4f46e5;
  --black-900: #111827;
  --background-orange-brand: rgba(255, 100, 77, 1);
  --background-orange-pale: rgba(255, 100, 77, 0.4);
  --background-black-gentle: rgba(40, 43, 51, 1);
  --background-blue-well: rgba(50, 50, 93, 1);
  --background-grey-pale: #f4f4f4;
  --background-white: #ffffff;
  --background-grey: #f7e8e9;
  --box-shadow: rgba(0, 0, 0, 0.25);
  --pale-basic: rgb(251, 248, 243);

  --google-blue: #4285f4;
  --button-active-blue: #1669f2;
}

@import "../../node_modules/antd/dist/antd.less";

.ant-layout-sider-children {
  background-color: #ffffff;
}

.ant-layout {
  background-color: var(--background-base);
}

.ant-layout-footer {
  background-color: var(--background-base);
}

.site-layout.site-layout-background {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

header.ant-layout-header.site-layout-background {
  background-color: var(--background-base);
}

.ant-layout-sider-trigger {
  background-color: #ffffff;
  color: var(--black-1);
}

div.ant-layout-sider-dark {
  background-color: var(--background-base);
  border-color: lime;
}

.logo {
  height: 65px;
  background-image: url("/src/img/logo/tenstars.svg");
  background-repeat: no-repeat;
  background-color: white;
  padding-top: 6px;
  padding-bottom: 25px;
  padding-left: 6px;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

.border-orange-brand {
  border-color: var(--background-orange-brand) !important;
}

.bg-orange-brand {
  background-color: var(--background-orange-brand) !important;
}

.bg-orange-gradient {
  background: rgb(233, 134, 44);
  background: linear-gradient(
    144deg,
    rgba(233, 134, 44, 1) 0%,
    rgba(238, 183, 83, 1) 75%,
    rgba(235, 223, 102, 1) 100%
  );
}

.bg-blueish {
  background-color: var(--bluish);
}

.bg-black-gentle {
  background-color: var(--background-black-gentle);
}

.bg-grey-pale {
  background-color: var(--background-grey-pale);
}

.bg-white {
  background-color: var(--background-white);
}

.bg-img-fancy {
  background-image: url("/src/img/fancy_background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.bg-dotted {
  background: url("../img/HP/dotted_background.svg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
}

.bg-confetti {
  background: url("../img/confetti.svg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
}

.br-rad-1 {
  border-radius: 4px;
}

.white {
  color: var(--background-white);
}

.color-orange-brand {
  color: var(--orange-brand);
}

.color-gentle-black {
  color: var(--black);
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-lg-plus {
  font-size: 1.3rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-1_5xl {
  font-size: 1.3rem;
  line-height: 2rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.grayscale {
  filter: saturate(10%) grayscale(20%) opacity(100%);
}

.border-white-3 {
  border: solid 8px white;
}

.frame-1 {
  border: 1px solid var(--some-other-grey);
  padding: 4px;
}

.shadow-simple {
  box-shadow: 0 4px 2px var(--box-shadow);
  border-radius: 8px;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.blinking {
  animation: blinkingText 1.2s infinite;
}

.main-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
}

.container-main {
  padding: 24px 6%;
  min-height: 100vh;

  @media (min-width: 576px) AND (max-width: 1024px) {
    padding: 24px 4% 16px 4%;
  }

  @media (max-width: 576px) {
    padding: 24px 8px 16px 8px;
  }
}

.container-loading {
  min-height: 60vh;
}

html {
  height: 100%;
}

body {
  margin: 0;
  background: var(--background-white);
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", "Icons16",
    sans-serif;
  height: 100%;
}

p {
  font-weight: 400;
  font-size: 14px;
}

.homepage-content {
  padding-left: 4%;
  padding-right: 4%;
}

.home-start {
  margin-top: 6vh;
}

h1 {
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", "Icons16",
    sans-serif;
  color: var(--black-1);
  @media (max-width: 1024px) {
    font-size: 2.3em !important;
  }
}

h2 {
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", "Icons16",
    sans-serif;
  color: var(--black-1);
  font-size: 2em !important;

  @media (max-width: 1024px) {
    font-size: 1.6em !important;
  }
}

h3 {
  font-size: 1.5em !important;
  font-weight: 300;

  @media (max-width: 1024px) {
    font-size: 1.2em !important;
  }
}

.error-page h3 {
  font-family: "Playfair Display", serif;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 64px !important;
  line-height: 48px !important;
  color: #000000 !important;
  margin: 32px 0;
}

h4 {
  font-weight: bold;
  color: var(--black-1);
  font-size: 1.6em;
}

h5 {
  font-family: "Montserrat", sans-serif;
  font-size: larger !important;
  font-weight: 800 !important;
}

.media-body {
  @media (max-width: 576px) {
    margin-bottom: 16px;
  }
}

.section {
  margin-bottom: 60px;
  padding-left: 22.5px;
  padding-right: 22.5px;
  @media (min-width: 1024px) {
    margin-bottom: 80px;
  }

  h1 {
    font-size: 1.6em !important;
    line-height: 1.3em;
    @media (min-width: 1024px) {
      font-size: 2.3em !important;
    }
  }

  h4 {
    line-height: 1.3em;
    align-items: center;
    margin: 1.6em 0;
  }

  p {
    font-size: 1.2em;
    line-height: 24px;
  }

  video {
    border: 1px solid var(--some-other-grey);
    padding: 4px;
  }
}

.home-block {
  background-color: var(--orange-pale);
  border-radius: 4px;
  margin: 16px 0 16px 0;
  padding: 40px;
  min-height: 380px;
}

.home-block img {
  height: 90px;
}

.btn.btn-secondary {
  font-weight: 700;
}

.btn.btn-primary {
  border-color: var(--orange-brand) !important;
  background-color: var(--orange-brand) !important;
  font-weight: 700;
  padding: 0.5rem 1.5rem !important;

  &.negative {
    border-color: var(--background-orange-intense) !important;
    background-color: transparent !important;
    color: var(--orange-brand) !important;
  }

  &.well {
    background-color: var(--background-blue-well) !important;
    border-color: var(--background-blue-well) !important;
  }

  &.stripe {
    border-color: var(--stripe-color) !important;
    background-color: var(--stripe-color) !important;
    color: var(--white) !important;
  }

  &:disabled {
    opacity: 0.35;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.btn.btn-menu {
  background-color: var(--orange-brand);
  border-color: var(--orange-brand);
  font-weight: 300;
  color: white;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.5;
  margin-left: 8px;

  @media (max-width: 768px) {
    // intentionally resetting left to 0
    margin: 8px 0 0 0;
  }
}

.btn.btn-white {
  background-color: white;
  color: var(--black-1);
  padding: 1em 2em 1em 2em;
  font-weight: 300;
  text-align: center;
}

.btn-helper {
  font-size: 15px;
  color: var(--black-1);
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.95;
}

.connect-demo {
  background-color: var(--background-white);
  margin: 4%;
  padding: 8%;
  border-radius: 4px;
}

.navbar-brand {
  font-size: 24px;
  color: var(--black-1);
}

.tenstars-text-logo {
  letter-spacing: 0.97px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: normal;
  vertical-align: middle;

  :hover {
    color: var(--background-orange-brand) !important;
  }
}

.navbar-toggler button {
  color: black;
}

.nav-link {
  font-size: 14px;
}

.menu-link,
.menu-link:link,
.menu-link:visited {
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: normal;
}

.menu-link:hover,
.menu-link:active,
.sidebar-link:hover,
.sidebar-link:active {
  color: var(--background-orange-brand) !important;
  font-weight: normal;
}

.nav-item.nav-link {
  text-align: right;
  margin-right: 10px;

  &.text-link {
    margin-top: 12px;
  }
}

.footer {
  padding: 32px 24px 24px 24px;
  background-color: var(--black-42);
  color: white;
  position: relative;
  bottom: 0;
  width: 100%;

  .footer-usp {
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: white;
    font-size: 21px;
  }

  .footer-detail {
    width: 100%;
    border-top: white 1px solid;
    padding-top: 24px;
    font-size: 12px;
    letter-spacing: 0.2em;
  }

  ul {
    padding: 0;
  }

  li {
    font-size: 14px;
    list-style: none;
    line-height: 1.5;
    text-decoration: none;
    color: white;
  }

  li a,
  li a:visited {
    font-size: 14px;
    line-height: 1.5;
    color: white;
  }

  p {
    padding-top: 14px;
    font-size: 14px;
    line-height: 1.5;
    color: white;
  }
}

.price-box {
  background-color: var(--background-grey);
  border-radius: 3px;
  padding: 30px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  margin-top: 32px;

  h4 {
    font-size: 12px;
  }

  button {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    padding: 12px 30px;
    background-color: var(--black-1);
    color: white;
    line-height: 1.5;
    border-radius: 3px;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
}

.price-detail-figure {
  font-size: 2.4em;
  font-weight: bolder;
}

.price-list {
  li {
    display: list-item;
    text-align: -webkit-match-parent;
    list-style: none;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 24px;
  }
}

.price-other-plans {
  border-radius: 3px;
  background-color: var(--lighter-yellow);
  padding: 32px;

  img {
    border-radius: 50%;
    border: var(--background-grey) 8px solid;
  }
}

.demo-section {
  margin-top: 24px;
  padding: 32px 16px;
  background: white;
  border-radius: 8px;
  max-width: 600px;
}

.config-item-box {
  margin: 16px 0;
}

.config-item {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--black-1);
}

.icon-container {
  position: relative;
  display: inline-block;
  border-radius: 5px;
  background-color: white;
}

/* Create the hidden pseudo-element */
/* include the shadow for the end state */
.icon-container::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}

.ct-bar {
  fill: none;
  stroke-width: 32px;
}

.ct-label {
  color: var(--black-1);
}

.ct-grid {
  stroke: var(--some-other-grey);
}

// Line color of the Charts we are using more
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: var(--chart-series-1);
}

.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
  stroke: var(--chart-series-2);
}

.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
  stroke: var(--chart-series-3);
}

.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
  stroke: var(--chart-series-4);
}

.ct-series-e .ct-bar,
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut {
  stroke: var(--chart-series-5);
}

svg.ct-chart-bar,
svg.ct-chart-line {
  overflow: visible;
  margin-bottom: 12px;
}

.vertical-chart .ct-label.ct-label.ct-horizontal {
  position: fixed;
  justify-content: flex-end;
  text-align: right;
  transform-origin: 100% 0;
  transform: translate(-100%) rotate(-45deg);
  white-space: nowrap !important;
}

.ct-legend {
  padding: 10px;
  position: relative;
  z-index: 10;
  list-style: none;
  text-align: center;

  ul {
    list-style: none;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  li {
    position: relative;
    padding-left: 32px;
    margin-right: 10px;
    margin-bottom: 3px;
    cursor: pointer;
    display: inline-block;
  }

  li:before {
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    content: "";
    border: 3px solid transparent;
    border-radius: 2px;
    margin-right: 12px;
  }

  li.ct-series-0:before {
    background-color: var(--chart-series-1);
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    content: "";
    border: 3px solid transparent;
    border-radius: 2px;
  }

  li.ct-series-1:before {
    background-color: var(--chart-series-2);
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    content: "";
    border: 3px solid transparent;
    border-radius: 2px;
  }

  li.ct-series-2:before {
    background-color: var(--chart-series-3);
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    content: "";
    border: 3px solid transparent;
    border-radius: 2px;
  }

  li.ct-series-3:before {
    background-color: var(--chart-series-4);
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    content: "";
    border: 3px solid transparent;
    border-radius: 2px;
  }

  li.ct-series-4:before {
    background-color: var(--chart-series-5);
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    content: "";
    border: 3px solid transparent;
    border-radius: 2px;
  }
}

.ct-horizontal-bars .ct-labels * {
  height: 14px !important;
}

.progress-donut {
  .full {
    .ct-slice-donut {
      stroke: #4ade80;
    }
  }

  .empty {
    .ct-slice-donut {
      stroke: rgba(107, 114, 128, var(--tw-text-opacity));
    }
  }

  .centered-number {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
  }
}

.crazy-churn {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  color: var(--black-1);
  text-align: center;
  cursor: default;

  li.ct-series-0,
  li.ct-series-1,
  li.ct-series-2,
  li.ct-series-3 {
    cursor: default;
    margin: 12px;
  }
}

.clickable {
  cursor: pointer;
}

.max-width-60 {
  max-width: 60%;
  @media (max-width: 576px) {
    max-width: 100%;
  }
}

.code-block {
  background-color: var(--background-grey-pale);
  position: relative;
}

.code-block-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.section-rounded-shadow {
  max-width: 100%;
  overflow-x: hidden;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 4px var(--box-shadow);
}

.container {
  @media (max-width: 576px) {
    width: 90%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

.calendar {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(73, 80, 87);

  .react-calendar__tile--hasActive,
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: var(--background-orange-intense);
    color: var(--white);
  }

  .react-calendar__tile--now,
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: var(--background-orange-pale);
    color: var(--black);
  }

  .react-calendar__tile {
    height: 40px;
    padding: 8px 0;
    width: 40px;
  }
}

.signin-form {
  margin-top: 64px;
  border: 12px white solid;
  padding: 40px 24px 40px 24px;
  background: #fafbfc;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: left;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.card-section {
  background-color: va(--background-base);
  padding: 16px;
  border-radius: 3px;
}

.device-wrapper {
  &.portrait {
    max-width: 50% !important;
  }

  &.landscape {
    max-width: 100% !important;
  }

  &.wide {
    max-width: 100% !important;
  }
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.price-editor {
  font-family: "Montserrat", sans-serif;
  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    color: var(--black-900);
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    /* indigo/600 */
    color: var(--indigo-600);
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--black-900);
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--black-900);
  }
}

.popover {
  z-index: 2060 !important;
}

.highlighted_effect {
  display: inline-block;
  color: var(--background-white);
  @media (min-width: 1024px) {
    color: var(--background-white);
  }
}
.highlighted_effect:after {
  content: "";
  position: absolute;
  width: calc(100%);
  top: 0;
  left: -5px;
  right: 0;
  bottom: 0;
  background: var(--background-orange-brand);
  -moz-border-radius: 2px;
  border-radius: 2px;
  z-index: -1;
  -webkit-transform: rotate(-1deg);
  -moz-transform: rotate(-1deg);
  transform: rotate(-1deg);
  @media (min-width: 1024px) {
    content: "";
    position: absolute;
    width: calc(100% + 10px);
    top: 0;
    left: -5px;
    right: 0;
    bottom: 0;
    background: var(--background-orange-brand);
    -moz-border-radius: 2px;
    border-radius: 2px;
    z-index: -1;
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
}

.fancy-background {
  background: url("../img/pattern_background.svg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media (min-width: 1024px) {
    background: url("../img/pattern_background.svg") no-repeat center center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
}

.fancy-background-light {
  background: url("../img/fancy_background_light.svg") repeat-y center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.blue-background-bottom {
  background: url("../img/background_blue_bottom.png") no-repeat bottom center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: unset;
}

input.form-control {
  border-color: rgb(216, 218, 229);
  border-radius: 4px;
  font-size: 0.9rem;
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", "Icons16",
    sans-serif;
}

// todo: organize classes to not repeat background colors, shadows, etc

.animationContainer {
  min-height: 430px;
  max-width: 392px;
  margin-right: auto;
  margin-left: auto;
}

.border-4 {
  border: 4px solid;
}
.border-blue-301 {
  border-color: #c4c4c4;
}
.background-blue-301 {
  background: linear-gradient(0deg, #b7e5ff, #b7e5ff 40%, #c4c4c4);
}

.google-btn {
  width: 184px;
  height: 42px;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background-color: var(--google-blue);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #ffffff;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  &:hover {
    box-shadow: 0 0 6px var(--google-blue);
    color: #ffffff;
  }
  &:active {
    background: var(--button-active-blue);
  }
}

.app-preview-parallax {
  position: absolute;
  right: 10%;
  top: 330px;
  min-width: 300px;
}

.cohort-container {
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", "Icons16",
    sans-serif;
  font-weight: 500;
}

@primary-color: #FF644D;@body-background: #FBF8F3;@background-color-base: #FBF8F3;